/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import Vue from 'vue'

Vue.filter('round', function (value, decimals) {
  if (!value) {
    value = 0
  }

  if (!decimals) {
    decimals = 0
  }

  value = Math.round(value * Math.pow(10, decimals)) / Math.pow(10, decimals)
  return value
})

Vue.filter('formatRating', function (value) {
  if (!value) {
    value = 0
  }
  // no rounding off, just show upto 1st decimal
  value = value.toString().substring(0, 3)
  return value
})
Vue.filter('showThousandsInK', (value, decimals) => {
  if (value < 1000) {
    return value
  }

  value = value / 1000

  if (!decimals) {
    decimals = 0
  }

  value = Math.round(value * Math.pow(10, decimals)) / Math.pow(10, decimals)
  return value + 'k'
})

Vue.filter('showThousandsInCommas', (value, decimals) => {
  if (value < 1000) {
    return value
  }

  let x = value
  x = x.toString()
  let lastThree = x.substring(x.length - 3)
  const otherNumbers = x.substring(0, x.length - 3)
  if (otherNumbers != '') {
    lastThree = ',' + lastThree
  }
  const res = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ',') + lastThree
  return res
})

Vue.filter('showInMinutesOrHours', function (value) {
  if (!value) {
    value = 0
  }

  let finalMinutes = Math.round(value / 60)
  const finalHours = Math.round(finalMinutes / 60)

  if (finalMinutes === 0) finalMinutes = 1

  let readingTimeInNativeLanguage
  if (finalHours >= 1) {
    readingTimeInNativeLanguage = finalHours + ' hrs'
  } else {
    readingTimeInNativeLanguage = finalMinutes + ' mins'
  }
  return readingTimeInNativeLanguage
})

Vue.filter('getPratilipiTypeInNativeLanguage', function (value) {
  switch (value) {
    case 'STORY':
      return '__("_pratilipi_type_story")'
    case 'POEM':
      return '__("_pratilipi_type_poem")'
    case 'ARTICLE':
      return '__("_pratilipi_type_article")'
    case 'BOOK':
      return '__("_pratilipi_type_book")'
    case 'MAGAZINE':
      return '__("_pratilipi_type_magazine")'
  }
})

Vue.filter('getTranslatedLoginErrorMessage', function (value) {
  // console.log(value)
  switch (value) {
    case 'Email not registered.':
      return '__("email_not_registered")'
    case 'Invalid Credentials!':
      return '__("login_failed")'
    case 'The email address is already registered':
      return '__("email_already_registered")'
    default:
      return value
  }
})

Vue.filter('convertDate', function (value) {
  const d = new Date(value)

  function day (d) {
    return d < 10 ? '0' + d : d
  }

  function month (m) {
    const months = [
      '__("month_jan")',
      '__("month_feb")',
      '__("month_mar")',
      '__("month_apr")',
      '__("month_may")',
      '__("month_jun")',
      '__("month_jul")',
      '__("month_aug")',
      '__("month_sep")',
      '__("month_oct")',
      '__("month_nov")',
      '__("month_dec")'
    ]
    return months[m]
  }
  return [day(d.getDate()), month(d.getMonth()), d.getFullYear()].join(' ')
})

Vue.filter('readingTimeSchemaOrgFormat', function (value) {
  let readingTimeInSchemaFormat = 'PT'

  // Calculating the read time in schema markup format
  if (!value) {
    value = 0
  }

  let readTimeMinutes = Math.round(value / 60)
  const readTimeHours = Math.round(readTimeMinutes / 60)

  if (readTimeMinutes == 0) readTimeMinutes = 1

  if (readTimeHours >= 1 && readTimeMinutes >= 60) {
    readingTimeInSchemaFormat += readTimeHours + 'H'
  } else {
    readingTimeInSchemaFormat += '0H'
  }

  if (readTimeMinutes > 1) {
    readingTimeInSchemaFormat += readTimeMinutes + 'M'
  } else {
    readingTimeInSchemaFormat += '0M'
  }
  return readingTimeInSchemaFormat
})

Vue.filter('listingDateSchemaOrgFormat', function (value) {
  const datePublishedInSchemaFormat = ''
  const d = new Date(value)
  function day (d) {
    return d < 10 ? '0' + d : d
  }
  function month (m) {
    return m < 10 ? '0' + m : m
  }

  return [d.getFullYear(), month(d.getMonth() + 1), day(d.getDate())].join(
    '-'
  )
})

Vue.filter('secondsToMinuteSec', (time) => {
  const minutes = Math.floor(time / 60)
  const seconds = Math.round(time - minutes * 60)

  return `${minutes}:${
    String(seconds).length == 1 ? '0' + seconds : seconds
  }`
})

Vue.filter('pluralize', (word, amount) =>
  amount > 1 || amount == 0 ? `${word}s` : word
)

// for web expt - recommendation
Vue.filter('normaliseReadCount', function (value) {
  if (!value) {
    return 0
  }
  let finalValue = Math.floor(value / 100000)
  if (finalValue > 0) {
    return finalValue + 'L+'
  }
  finalValue = Math.floor(value / 1000)
  if (finalValue > 0) {
    return finalValue + 'K+'
  }

  return value
})

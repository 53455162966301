import Vue from 'vue'
import Vuex from 'vuex'

import moment from 'moment'

import getters from './getters'
import mutations from './mutations'

Vue.use(Vuex)

// export default new Vuex.Store({
//   state: {
//     todos: [
//       {
//         id: moment().format('x'),
//         title: `I'm your new todo!`,
//         text: 'Just wish I remembered what I wanted to write..'
//       }
//     ],
//     props:
//       {
//         id: '1',
//         language: 'HINDI',
//         cards: '10',
//         tags: '',
//         headingcolor: '#212121',
//         contentcolor: '#616161',
//         iconcolor: '#A3A3A3',
//         readerbgcolor: '#f3f4f6',
//         readercontentcolor: '#616161',
//         buttoncolor: '#d32f2f',
//         buttontextcolor: '#ffffff',
//         readnextcardbg: '#fff'
//       }
//   },
//   getters,
//   mutations
// })

export const createStore = (obj) => {
  return new Vuex.Store({
    state: {
      todos: [
        {
          id: moment().format('x'),
          title: `I'm your new todo!`,
          text: 'Just wish I remembered what I wanted to write..'
        }
      ],
      props:
          {
            id: '1',
            language: 'HINDI',
            cards: '10',
            tags: '',
            headingcolor: '#212121',
            contentcolor: '#616161',
            iconcolor: '#A3A3A3',
            readerbgcolor: '#f3f4f6',
            readercontentcolor: '#616161',
            buttoncolor: '#d32f2f',
            buttontextcolor: '#ffffff',
            readnextcardbg: '#fff',
            cardbgcolor: '#ffffff00'
          },
      configs: []
    },
    getters,
    mutations
  })
}

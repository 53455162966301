import Vue from 'vue'
import Router from 'vue-router'
import List from './views/List.vue'
import Edit from './views/Edit.vue'
import Home from './views/Home.vue'
import Series from './views/Series.vue'
import Reader from './views/Reader.vue'
Vue.use(Router)

let routes = [
  {
    path: '/reader/:slug',
    name: 'reader',
    component: Reader
  },
  {
    path: '/list',
    name: 'list',
    component: List
  },
  {
    path: '/edit/:id?',
    name: 'edit',
    component: Edit
  },
  {
    path: '/series/:slug',
    name: 'series',
    component: Series
  },
  {
    path: '/',
    name: 'home',
    component: Home
  }
]

export const pushRoutes = (obj) => {
  routes.push(
    {
      path: '/' + obj['id'].nodeValue,
      name: 'home' + obj['id'].nodeValue,
      component: Home
    }
  )
}

export const setRoutes = () => {
  // // console.log(routes)
  return new Router({ routes, mode: 'abstract' })
}

export const createRoutes = () => {
  return new Router({
    routes: [
      {
        path: '/list',
        name: 'list',
        component: List
      },
      {
        path: '/edit/:id?',
        name: 'edit',
        component: Edit
      },
      {
        path: '/',
        name: 'home',
        component: Home
      },
      {
        path: '/series/:slug',
        name: 'series',
        component: Series
      },
      {
        path: '/reader/:slug',
        name: 'reader',
        component: Reader
      }
      // {
      //   path: '/*',
      //   redirect: { name: 'list' }
      // }
    ],
    mode: 'abstract',
    linkActiveClass: 'active'
  })
}

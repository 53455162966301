/* eslint-disable no-undef */
import Vue from 'vue'
export const eventBus = new Vue()
let listnerBinded = false
let rewardedSlot = null
let readyEvent = null
let id = null
export const inventory = {
  rewarded_ad: {
    path: `/6355419/Travel/Europe/France/Paris/rewarded_ad`,
    size: [728, 90],
    dom_id: 'rewarded_ad'
  }
}

export const defineSlots = (i) => {
  // console.log('define slot', id, i)
  if (id != null) {
    if (id !== i) {
      rewardedSlot = null
      listnerBinded = null
      readyEvent = null
    }
  } else {
    id = i
  }
  if (rewardedSlot != null) {
    return
  }
  window.googletag.cmd.push(createAdsSlot)
}

const createAdsSlot = () => {
  rewardedSlot = googletag.defineOutOfPageSlot('/22827604977/reward-test', googletag.enums.OutOfPageFormat.REWARDED)
  // console.log(' File: ads.js Message : rewarded ad created ', rewardedSlot)
  if (!rewardedSlot) {
    return
  }
  rewardedSlot.addService(window.googletag.pubads())
  window.googletag.enableServices()
  window.googletag.display(rewardedSlot)

  if (listnerBinded) {
    return
  }
  listnerBinded = true
  window.googletag.pubads().addEventListener('rewardedSlotReady', (event) => {
    // console.log(' File: ads.js Message : slot ready', event.slot.getSlotId().getId())
    readyEvent = event
  })
  window.googletag.pubads().addEventListener('rewardedSlotClosed', () => {
    dismissRewardedAd()
  })
  window.googletag.pubads().addEventListener('rewardedSlotGranted', (event) => {
    dismissRewardedAd()
    // console.log(`rewardReceived-${id}`)
    eventBus.$emit(`rewardReceived-${id}`, event.payload)
  })
  window.googletag.pubads().addEventListener('slotRequested', (event) => {
    // console.log(' File: ads.js Message : requested', event.slot.getSlotId().getId())
  })
}

export const checkIfSlotDefined = () => {
  return rewardedSlot != null
}

export const checkIfSlotReady = () => {
  // console.log(' File: ads.js Message : ready event value ', readyEvent)
  return readyEvent != null
}

export const displayRewardedAd = () => {
  // console.log(' File: ads.js Message : displayRewardedAd', readyEvent)
  if (readyEvent == null) {
    return
  }
  readyEvent.makeRewardedVisible()
}

function dismissRewardedAd () {
  // console.log(' File: ads.js Message : dismissed')
  googletag.destroySlots()
  rewardedSlot = null
  readyEvent = null
  setTimeout(function () {
    defineSlots(id)
  }, 1000)
}

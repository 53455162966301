<template>
  <div class="full-modal">
  <div class="header">
    <button @click='goBack'>go back</button>
  </div>
  <div class="content">
    <div v-for="part in partList" :key="part.id">
    <!-- <img :src="series.attributes.coverImageUrl" alt="" class="seriesCoverImage"> -->
    <br/>
    <button @click='goToReaderPage(part.pratilipiId)'>Read part {{part.part}}</button>
    </div>
  </div>

  </div>
</template>

<script>
export default {
  data () {
    return {
      partList: []
    }
  },
  methods: {
    goBack () {
      this.$router.go(-1)
    },
    goToReaderPage (id) {
      this.$router.push({ name: 'reader', params: { slug: id } })
    },
    getData () {
      fetch('https://android.pratilipi.com/series/v2.0/id/' + this.$route.params.slug, { headers: { 'accesstoken': localStorage.getItem('accesstoken') } })
        .then(async (response) => {
          const data = await response.json()
          if (!response.ok) {
            // get error message from body or default to response statusText
            const error = (data && data.message) || response.statusText
            return Promise.reject(error)
          }
          // console.log('parts', data)
          this.partList = data.data.seriesPart.published
        })
        .catch((error) => {
          this.errorMessage = error
          console.error('There was an error!', error)
        })
    }
  },
  computed: {
  },
  created () {

  },
  mounted () {

  },
  watch: {
    '$route.params.slug': {
      handler: function (value) {
        this.getData()
      },
      deep: true,
      immediate: true
    }
  }
}
</script>
<style>
.full-modal{
  position: fixed; /* Stay in place */
  z-index: 9999; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(252, 249, 249); /* Fallback color */
}
.header{
  height: 45px;
  background-color: aliceblue;
  position: fixed;
  width: 100%;
}
.content{
  margin-top: 50px;
}
</style>

<template>
  <div :id="getProps.id" class="main">
    <div id="rewarded_ad" class="vue-ad"></div>
    <br>
    <keep-alive> <router-view /> </keep-alive>
  </div>
</template>

<script>
import { eventBus } from './utils/ads'
import '@/static_scripts/amplitude_analytics.js'
import { setAmplitudeIdentity } from './utils/methods'
import { mapMutations, mapGetters } from 'vuex'
import AWS from 'aws-sdk'
export default {
  props: {
    'language': {
      type: String,
      default: 'MARATHI'
    },
    'cards': {
      type: String,
      default: '10'
    },
    'tags': {
      type: String,
      default: ''
    },
    headingcolor: {
      type: String,
      default: '#212121'
    },
    contentcolor: {
      type: String,
      default: '#616161'
    },
    cardbgcolor: {
      type: String,
      default: '#ffffff00'
    },
    iconcolor: {
      type: String,
      default: '#A3A3A3'
    },
    readerbgcolor: {
      type: String,
      default: '#fff'
    },
    readercontentcolor: {
      type: String,
      default: '#616161'
    },
    buttoncolor: {
      type: String,
      default: '#d32f2f'
    },
    buttontextcolor: {
      type: String,
      default: '#ffffff'
    },
    readnextcardbg: {
      type: String,
      default: '#f1f1f1'
    },
    id: {
      type: String,
      default: '1'
    },
    adsfeature: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      activeTab: '',
      config: {}
    }
  },
  async created () {
    this.language = ['HINDI', 'ENGLISH', 'MARATHI', 'KANNADA', 'MALAYALAM', 'TAMIL', 'ODIA', 'PUNJABI'].indexOf(this.language.toUpperCase()) >= 0 ? this.language.toUpperCase() : 'MARATHI'
    // console.log('app created', this.language)
    this.editProps({ key: 'id', value: this.id })
    this.editProps({ key: 'language', value: this.language })
    this.editProps({ key: 'cards', value: this.cards })
    this.editProps({ key: 'tags', value: this.tags })
    this.editProps({ key: 'headingcolor', value: this.headingcolor })
    this.editProps({ key: 'contentcolor', value: this.contentcolor })
    this.editProps({ key: 'iconcolor', value: this.iconcolor })
    this.editProps({ key: 'readerbgcolor', value: this.readerbgcolor })
    this.editProps({ key: 'readercontentcolor', value: this.readercontentcolor })
    this.editProps({ key: 'buttoncolor', value: this.buttoncolor })
    this.editProps({ key: 'buttontextcolor', value: this.buttontextcolor })
    this.editProps({ key: 'readnextcardbg', value: this.readnextcardbg })
    this.editProps({ key: 'adsfeature', value: this.adsfeature })
    // console.log(this.getProps, 'props')
    await this.generateAccessToken()
    this.$router.push({ name: 'home', params: { 'id': this.id } })
  },
  watch: {
    'language': {
      handler: function (newvalue, oldvalue) {
        // // console.log(this.id, 'lang')
        if (oldvalue !== undefined) {
          this.language = ['HINDI', 'ENGLISH', 'MARATHI', 'KANNADA', 'MALAYALAM', 'TAMIL', 'ODIA', 'PUNJABI'].indexOf(this.language.toUpperCase()) >= 0 ? this.language.toUpperCase() : 'HINDI'
          this.editProps({ key: 'language', value: this.language })
          if (this.$route.name === 'home') {
            // console.log('lang ', this.language, this.$route.name, this.getProps.id)
            eventBus.$emit(`propsUpdated-${this.getProps.id}`)
          }
        }
      },
      deep: true,
      immediate: true
    },
    'cards': {
      handler: function (newvalue, oldvalue) {
        // // console.log(newvalue, oldvalue)
        if (oldvalue !== undefined) {
          this.editProps({ key: 'cards', value: this.cards })
          if (this.$route.name === 'home') {
            // // console.log('car ', this.cards, this.$route.name)
            eventBus.$emit(`propsUpdated-${this.getProps.id}`)
          }
        }
      },
      deep: true,
      immediate: true
    },
    'tags': {
      handler: function (newvalue, oldvalue) {
        // // console.log(newvalue, oldvalue)
        if (oldvalue !== undefined) {
          this.editProps({ key: 'tags', value: this.tags })
          if (this.$route.name === 'home') {
            // // console.log('car ', this.tags, this.$route.name)
            eventBus.$emit(`propsUpdated-${this.getProps.id}`)
          }
        }
      },
      deep: true,
      immediate: true
    }
  },
  mounted () {
    // console.log('app mounted')
    if (this.adsfeature) {
      const adScript = document.createElement('script')
      adScript.setAttribute(
        'src',
        'https://www.googletagservices.com/tag/js/gpt.js'
      )
      document.head.appendChild(adScript)
      // eslint-disable-next-line no-use-before-define
      var googletag = googletag || {}
      googletag.cmd = googletag.cmd || []
    }
    if (document.getElementById('pratilipi-reader') === undefined) {
      // console.log('creating')
      const readerDiv = document.createElement('div')
      readerDiv.id = 'pratilipi-reader'

      document.body.appendChild(readerDiv)
    }

    setAmplitudeIdentity(this.getProps.language)
    this.initializeAWS()
  },
  methods: {
    ...mapMutations([
      'editProps',
      'pushConfig'
    ]),
    initializeAWS () {
      AWS.config.region = 'ap-south-1' // Region
      let cognitoIdentity
      cognitoIdentity = 'ap-south-1:a51cd3be-642b-4a0b-9852-1311f8bc5228'
      AWS.config.credentials = new AWS.CognitoIdentityCredentials({
        IdentityPoolId: cognitoIdentity
      })

      AWS.config.credentials.get(function (err) {
        // attach event listener
        if (err) {
          console.error(err)
        }
      })
    },
    async generateAccessToken () {
      // console.log('herhehrh', localStorage.getItem('pratilipi-access-token'))
      if (localStorage.getItem('pratilipi-access-token') == null) {
        await fetch('https://android-gamma.pratilipi.com/user/accesstoken')
          .then(async (response) => {
            const data = await response.json()
            if (!response.ok) {
              // get error message from body or default to response statusText
              const error = (data && data.message) || response.statusText
              return Promise.reject(error)
            }
            // console.log('pratilipi-access-token', data)
            localStorage.setItem('pratilipi-access-token', data.accessToken)
          })
          .catch((error) => {
            this.errorMessage = error
            console.error('There was an error!', error)
          })
      }
    }
  },
  computed: {
    ...mapGetters([
      'getProps'
    ])
  }
}
</script>

import Vue from 'vue'
import App from './App.vue'
import { createRoutes } from './router'
import { createStore } from './store/index'

// plugins

import './assets/css/main.scss'
import './filters'
// widget setup
// (optional) 'Custom elements polyfill'
import 'document-register-element/build/document-register-element'
// vue-custom-element by karol-f
import vueCustomElement from 'vue-custom-element'
import { library } from '@fortawesome/fontawesome-svg-core'

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

/* import specific icons */
import { faUserSecret, faEye, faStar, faClock, faArrowLeft } from '@fortawesome/free-solid-svg-icons'

import VuePortal from '@linusborg/vue-simple-portal'

/* add icons to the library */
library.add(faUserSecret, faEye, faStar, faClock, faArrowLeft)

/* add font awesome icon component */
Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.use(vueCustomElement)
Vue.use(VuePortal, {
  name: 'portal' // optional, use to rename component
})

Vue.config.productionTip = false

// new Vue is not needed anymore, as you're initializing the app through vue-custom-element
// new Vue({
//   router,
//   store,
//   render: h => h(App)
// }).$mount('#app')

// use vue-custom-element
// // console.log('before listners 3')
// var body = document.getElementsByTagName('body')[0]

// body.addEventListener('load', init, false)

window.addEventListener('DOMContentLoaded', setObserver, false)

// let url = location.href
// window.addEventListener('click', () => {
//   requestAnimationFrame(() => {
//     if (url !== location.href) {
//       // console.log('url changed')
//       url = location.href
//       init()
//     }
//   })
// }, true)

let observer = new MutationObserver((mutations) => {
  mutations.forEach((mutation) => {
    let oldValue = mutation.oldValue
    let newValue = mutation.target.textContent
    if (oldValue !== newValue) {
      // console.log('observer called')
      init()
    }
  })
})

observer.observe(document.body, {
  childList: true,
  subtree: true
})

function setObserver () {
  // // console.log('setting observers dom content loaded')
  observer.observe(document.body, {
    childList: true,
    subtree: true
  })
}
// // console.log('body', document.body)

init()

function init () {
  // console.log('init loaded')
  var arr = document.getElementsByClassName('pratilipi-widget')
  // // console.log(arr, arr.length, 'take 3')
  for (var i = 0; i<arr.length; i++) {
    App.store = createStore(0)
    App.router = createRoutes()
    // // console.log(arr[i])
    var name = arr[i].tagName.toString().toLowerCase()
    // // console.log(name, 'name1')
    Vue.customElement(name, App)
  }
  setObserver()
}

export const fetchCall = async (method, api, body, retry) => {
  const response = await fetch('https://android.pratilipi.com/' + api, {
    'headers': {
      'accept': '*/*',
      'accept-language': 'en-GB,en-US;q=0.9,en;q=0.8',
      'apollographql-client-name': 'WEB_prod',
      'apollographql-client-version': '1.0.0',
      'content-type': 'application/json',
      'sec-fetch-dest': 'empty',
      'sec-fetch-mode': 'cors',
      'sec-fetch-site': 'same-origin',
      'accesstoken': localStorage.getItem('pratilipi-access-token')
    },
    'referrerPolicy': 'strict-origin-when-cross-origin',
    'body': body,
    'method': method,
    'mode': 'cors',
    'credentials': 'include'
  })
  const data = await response.json()
  if (!response.ok) {
    // get error message from body or default to response statusText
    const error = (data && data.message) || response.statusText
    // console.log(api, ' api error', error, response.statusText)
    // eslint-disable-next-line eqeqeq
    if (response.statusText == 401) {
      if (retry === false) {
        refreshAccessToken(method, api, body)
      } else {
        alert('API FAILURE CONTACT TEAM PRATILIPI')
      }
    }
  } else {
    return data
  }
}

const refreshAccessToken = async (method, api, body) => {
  fetch('https://android.pratilipi.com/user/accesstoken')
    .then(async (response) => {
      const data = await response.json()
      if (!response.ok) {
        // get error message from body or default to response statusText
        const error = (data && data.message) || response.statusText
        // console.log('refresh token api error', error, response.statusText)
        return Promise.reject(error)
      }
      // console.log('pratilipi-access-token', data)
      localStorage.setItem('pratilipi-access-token', data.accessToken)
      fetchCall(method, api, body, true)
    })
    .catch((error) => {
      this.errorMessage = error
      console.error('There was an error!', error)
    })
}

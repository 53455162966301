/* eslint-disable no-undef */
export function isMobile () {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  )
}

export function setAmplitudeIdentity (language) {
  const identify = new amplitude.Identify()
  identify.set('USER_ID', '0')
  identify.set('CONTENT_LANGUAGE', language)
  // console.log(identify)
  amplitude.getInstance().identify(identify)
}

export function fireEvent (eventName, eventProps) {
  amplitude.getInstance().logEvent(eventName, eventProps)
  if (typeof gtag === 'undefined') {
    // console.log('gtag not defined')
  } else {
    gtag('event', eventName, eventProps)
  }
}

import { orderBy, find } from 'lodash'

export default {
  todos (state) {
    return orderBy(state.todos, ['id'], ['desc'])
  },
  getProps (state) {
    return state.props
  },
  getConfig: (state) => (id) => {
    return find(state.configs, { id: id })
  }
}
